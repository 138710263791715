import React from "react"
import { Link } from "gatsby"
import { format } from "date-fns"
import { it } from "date-fns/locale"

import styles from "./blog_excerpt.module.css"

const BlogEntry = ({ post }) => {
  return (
    <Link to={`/blog/${post.url.current}`}>
      <div className={styles.blogItem}>
        <h3 className={styles.blogDate}>
          {format(
            new Date(post.publishedAt || post._updatedAt),
            "d LLLL yyyy",
            {
              locale: it,
            }
          )}
        </h3>
        <h2 className={styles.blogTitle}>{post.title}</h2>
      </div>
    </Link>
  )
}

export default BlogEntry
