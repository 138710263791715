import React from "react"
import styles from "./intro.module.css"
import postStyles from "../components/post.module.css"

const Intro = () => {
  return (
    <div className={styles.container}>
      <h1 className={postStyles.postTitle}>Il nostro blog</h1>
      <p className={styles.copy}>
        Come possono gli avvocati far crescere la propria attività e migliorare
        il serizio offerto al cliente? Cosa c'è dietro a Time2Client e quali
        sono le sfide degli avvocati di oggi? Questo ed altro sul blog di
        Time2Client.
      </p>
    </div>
  )
}

export default Intro
