import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostLayout from "../components/postLayout"
import Post from "../components/blog_excerpt"
import SEO from "../components/seo"
import Intro from "../components/intro"

export const query = graphql`
  query AllBlogPostQuery {
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        title
        url {
          current
        }
        publishedAt
        _updatedAt
      }
    }
  }
`

const allSanityPosts = ({ data }) => {
  const posts = data.allSanityPost.nodes
  return (
    <Layout>
      <SEO title="Time2Client blog" />
      <PostLayout>
        <Intro />
        {posts.map(post => (
          <Post key={post.id} post={post} />
        ))}
      </PostLayout>
    </Layout>
  )
}

export default allSanityPosts
